import $http from '@/utitls/request'

/**
 * 分页获取短信记录信息
 */
export const GetSMSRecordPageList = (data) => {
    return $http.get('/api/SMSRecord/GetSMSRecordPageList', { params: data })
}
/**
 * 发送短信消息
 */
export const SendSMS = (data) => {
    return $http.get('/api/SMSRecord/SendSMS', { params: data })
}