<template>
  <div class="content">
    <TitleInfo title1="客户管理" title2="余量统计"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div>角色：</div>
          <el-select v-model="queryForm.roleID" placeholder="Select">
            <el-option
              v-for="item in roleInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div>用户名称：</div>
          <el-input
            v-model="queryForm.name"
            placeholder="请输入公司编码"
            class="keyInput"
          />
          <div>登录账号：</div>
          <el-input
            v-model="queryForm.account"
            placeholder="请输入负责人"
            class="keyInput"
          />
          <div>联系电话：</div>
          <el-input
            v-model="queryForm.phone"
            placeholder="请输入联系电话"
            class="keyInput"
          />
        </div>
        <div class="operate">
          <el-button
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
        </div>
      </div>
      <div class="tableInfo">
        <el-table
          :data="tableData"
          border
          class="tableInfo"
          style="width: 100%; height: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="Name" label="用户名称"></el-table-column>
          <el-table-column prop="RoleName" label="角色名称"></el-table-column>
          <el-table-column prop="Account" label="登录账号"></el-table-column>
          <el-table-column prop="Sex" label="性别"></el-table-column>
          <el-table-column prop="Age" label="年龄"></el-table-column>
          <el-table-column prop="Position" label="职位"></el-table-column>
          <el-table-column prop="Phone" label="联系电话"></el-table-column>
          <el-table-column prop="UseDays" label="剩余天数"></el-table-column>
          <el-table-column
            prop="action"
            label="操作"
            width="200"
            v-if="user.CanEdit"
          >
            <template #default="{ row }">
              <el-button
                size="small"
                type="danger"
                :icon="Promotion"
                @click="sendSMS(row)"
                >发送短信</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination
          v-model:current-page="queryForm.pageIndex"
          v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          popper-class="dropdownPopper"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { Search, Refresh, Promotion } from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetUseDayPageList } from '@/api/User'
import { GetRoleFillList } from '@/api/Role'
import { SendSMS } from '@/api/SMSRecord'
import { useStore } from 'vuex'
const queryForm = ref({
  roleID: '',
  name: '',
  account: '',
  phone: '',
  pageIndex: 1,
  pageSize: 10
})
const roleInfo = ref([
  {
    ID: '',
    Name: '全部'
  }
])
const total = ref(0)
const tableData = reactive([])
const user = ref({})
onMounted(() => {
  user.value = useStore().state.login.user
  GetRoleFillList()
    .then((res) => {
      if (res) {
        res.Data.forEach((item) => {
          roleInfo.value.push({
            ID: item.ID,
            Name: item.Name
          })
        })
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
  initGetList()
})
const initGetList = async () => {
  GetUseDayPageList(queryForm.value)
    .then((res) => {
      if (res) {
        total.value = res.Total
        tableData.length = 0
        res.Data.forEach((element) => {
          tableData.push(element)
        })
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const sendSMS = (row) => {
  console.log(row)
  SendSMS({
    ReceiveID: row.ID
  })
    .then((res) => {
      ElMessage({
        showClose: true,
        message: `${res.Data}`,
        type: 'success'
      })
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
}

const resetInfo = () => {
  queryForm.value = {
    roleID: '',
    name: '',
    account: '',
    phone: '',
    pageIndex: 1,
    pageSize: 10
  }
  initGetList()
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
